import cricketIcon from '../assets/images/common/icons/cricket.png';
import footballIcon from '../assets/images/common/icons/football.png';
import tennisIcon from '../assets/images/common/icons/tennis.png';
import horseRacingIcon from '../assets/images/common/icons/horseracingicon.svg';
import greyhoundIcon from '../assets/images/common/icons/greyhoundicon.svg';
import baseBallIcon from '../assets/images/common/icons/baseballicon.svg';
import basketBallIcon from '../assets/images/common/icons/basketballicon.svg';

export const EXCHANGE_EVENT_TYPES = [
  { id: '4', name: 'Cricket', slug: 'cricket', icon: cricketIcon },
  { id: '1', name: 'Football', slug: 'football', icon: footballIcon },
  { id: '2', name: 'Tennis', slug: 'tennis', icon: tennisIcon },
  { id: '7', name: 'Horserace', slug: 'horseracing', icon: horseRacingIcon },
  {
    id: '4339',
    name: 'Greyhound',
    slug: 'greyhoundracing',
    icon: greyhoundIcon,
  },
  { id: '7511', name: 'Baseball', slug: 'baseball', icon: baseBallIcon },
  { id: '7522', name: 'Basketball', slug: 'basketball', icon: basketBallIcon },
];

export const EXCH_SPORTS_MAP = {
  soccer: '1',
  football: '1',
  tennis: '2',
  cricket: '4',
  horseracing: '7',
  greyhoundracing: '4339',
  baseball: '7511',
  basketball: '7522',
};
