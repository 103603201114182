import React from 'react';
import './LoginPage.scss';
import LoginCard from '../../components/LoginForm/LoginForm';
import Card from '@material-ui/core/Card';
import webtitleImg from '../../assets/images/brand/title.png';
import { IonImg } from '@ionic/react';
import mobLogo from '../../assets/images/brand/mob-logo.png';

const LoginPage: React.FC = () => {
  const modalCloseHandler = () => {};

  return (
    <div className="bg">
      <Card className="card">
        <div className="login-mob-logo">
          <IonImg src={mobLogo}></IonImg>
        </div>
        <div className="login-form">
          <div className="mob-logo-div">
            <div className="border">
              <IonImg src={webtitleImg} className="logo" />
            </div>
          </div>
          <LoginCard modalCloseHandler={modalCloseHandler} />
        </div>
        {/* <div className='submitbtn'>Don’t have an account? <span>Sign Up</span></div> */}
      </Card>
    </div>
  );
};

export default LoginPage;
